import { ServiceTypeNames, ThirdPartyServiceTypeNames } from "@enerbit/base";
import { mountedDisassembleAppsEssBillingDetail } from "../helpers/spa/spa.helper";
type openDialogInformation = () => void;
type ServiceTypeName = ServiceTypeNames | ThirdPartyServiceTypeNames;

function isInvoiceConceptForServiceType(serviceTypeName: string): boolean {
  return [
    ...Object.values(ServiceTypeNames),
    ...Object.values(ThirdPartyServiceTypeNames),
  ].includes(serviceTypeName as ServiceTypeName);
}

export const getDialogComponentByName = (
  serviceTypeName: string,
  invoiceId: string,
  openDialogInformation: openDialogInformation
) => {
  if (serviceTypeName !== undefined && serviceTypeName !== null) {
    if (isInvoiceConceptForServiceType(serviceTypeName)) {
      return mountedDisassembleAppsEssBillingDetail(serviceTypeName, invoiceId);
    } else {
      return openDialogInformation();
    }
  }
};
