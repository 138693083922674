import FiltersOverview from "./reusable/FiltersOverview";
import CardOverview from "./reusable/CardOverview";
import TableBilling from "./reusable/TableBilling";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import UserInformationForm from "./reusable/DetailForm";
import { useEffect, useState } from "react";
import { getBillings, isLoading, setInvoices } from "../features/infoBilling/infoBilling";
import { CircularProgress, Box, DialogInformation } from "@enerbit/base";

const SectionInformation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading  = useSelector(isLoading);

  useEffect(() => {  
    callInitialStatement()
  }, [])

  const callInitialStatement = async () => {
    await dispatch(getBillings()) 
    dispatch(setInvoices("Facturas emitidas")); 
  }

  const openDialog = () => {
    setIsDrawerOpen(true);
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <FiltersOverview />
      <CardOverview />
      {
          loading ?
            <Box className="Loading-flex">
              <CircularProgress />
            </Box>
          : ( 
            <TableBilling openDialogInformation={openDialog} /> 
          )
        }
        <DialogInformation
        sx={{}}
        onCloseAction={() => {}}
        content={ <UserInformationForm /> }
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </>
  );
};

export default SectionInformation;
