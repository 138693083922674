import { configureStore } from "@reduxjs/toolkit"; 
import infoBillingReducer from "../features/infoBilling/infoBilling";

export const store = configureStore({
  reducer: { 
    infoBillingState : infoBillingReducer, 
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
