import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AdapterDayjs,
  Box,
  Button,
  CloseIcon,
  DatePicker,
  dayjs,
  Dayjs,
  Grid,
  IconButton,
  InputAdornment,
  LocalizationProvider,
  MenuItem,
  moment,
  SearchIcon,
  Select,
  SelectChangeEvent,
  serviceNames,
  TextField,
} from "@enerbit/base";
import {
  getBillings,
  isLoadingStatistics,
  params,
  selectedFilterInvoice,
  setInvoices,
  setParams,
  setResetPage,
  setResetSearch,
} from "../../features/infoBilling/infoBilling";
import { ParamFilters } from "../../models/infoBillingState";
import { AppDispatch } from "../../store/store";
const FiltersOverview = () => {
  const filters = useSelector(params);
  const dispatch = useDispatch<AppDispatch>();
  const selectedCard = useSelector(selectedFilterInvoice);

  const changeParams = (value: ParamFilters) => {
    dispatch(setParams({ ...filters, ...value }));
    dispatch(setResetPage());
  };

  const cleanCity = () => {
    let filterCity = { ...filters };
    delete filterCity.city;
    dispatch(setParams(filterCity));
  };

  let timeout: NodeJS.Timeout;
  const textInput = useRef<typeof TextField>(null);

  const cleanFilters = async () => {
    dispatch(setResetPage());
    dispatch(setResetSearch());
    dispatch(setParams({}));
    await dispatch(getBillings());
    dispatch(setInvoices(selectedCard));
  };

  useEffect(() => {
    if (
      filters?.since &&
      filters?.since != null &&
      (!filters?.until || filters?.until == null)
    ) {
      changeParams({
        until: new Date(
          moment(filters?.since).add(1, "months").toString()
        ).toISOString(),
      });
    }

    if (
      filters?.until &&
      filters?.until != null &&
      (!filters?.since || filters?.since == null)
    ) {
      changeParams({
        since: new Date(
          moment(filters?.until).startOf("month").toString()
        ).toISOString(),
      });
    }
  }, [filters?.since, filters?.until]);

  const callInitialStatement = async () => {
    await dispatch(getBillings());
    dispatch(setInvoices(selectedCard));
  };

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      callInitialStatement();
    }
  }, [filters]);

  const loading = useSelector(isLoadingStatistics);

  return (
    <>
      <form>
        <Grid sx={{ flexGrow: 1 }} container>
          <Grid item xs={12} md={2} lg={2} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Fecha Inicial</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={filters?.since ? dayjs(filters.since) : null}
                  onChange={(date: Dayjs | null) => {
                    changeParams({
                      since: date ? date.toISOString() : null,
                    });
                  }}
                  slotProps={{
                    textField: (params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "De",
                          format: "yyyy-MM-dd",
                        }}
                        sx={{ width: "100%" }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Fecha Final</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={filters?.until ? dayjs(filters.until) : null}
                  onChange={(date: Dayjs | null) => {
                    changeParams({
                      until: date ? date.toISOString() : null,
                    });
                  }}
                  slotProps={{
                    textField: (params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "De",
                          format: "yyyy-MM-dd",
                        }}
                        sx={{ width: "100%" }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "10px" }}>Tipo de servicio</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <Select
                fullWidth
                value={filters.service_type_name || ""}
                onChange={(event: SelectChangeEvent<string | null>) =>
                  changeParams({ service_type_name: event.target.value })
                }
              >
                <MenuItem value={""}></MenuItem>
                {Object.keys(serviceNames).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {serviceNames[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
            <Box sx={{ mb: "0.625rem" }}>Ciudad</Box>
            <Box className="TextField-without-border-radius Text-select-field">
              <TextField
                inputRef={textInput}
                disabled={loading}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  clearInterval(timeout);
                  timeout = setInterval(() => {
                    changeParams({ city: event.target.value });
                    clearInterval(timeout);
                  }, 700);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" color="secondary">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: filters?.city ? "visible" : "hidden" }}
                      onClick={() => {
                        dispatch(setResetSearch());
                        cleanCity();
                        //@ts-ignore
                        textInput.current.value = null;
                      }}
                    >
                      <CloseIcon className="Clear-icon-text" />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ mt: "10px", pt: "16px", pl: "10px" }}
            >
              <Button
                className="Clean-filters"
                color="primary"
                size="small"
                onClick={() => {
                  cleanFilters();
                  //@ts-ignore
                  textInput.current.value = null;
                }}
              >
                <Box className="Clean-filters-text">Limpiar todo</Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FiltersOverview;
