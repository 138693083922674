import { api, getValuePayment, initDownload } from "@enerbit/base";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { conversionVarsInitialState } from "../../enums/billing";
import { getParamsBilling } from "../../helpers/helpersBilling";
import { InfoBillingState, Invoice, Memos, ParamFilters, RootObject } from "../../models/infoBillingState";
import { StateStorage } from "../../models/stateStorage";

const invoiceState = {
  items: [],
  page: 0,
  size: 0,
  total: 0,
  pages: 0,
  next_page: 0,
  previous_page: 0,
  amount: 0,
};

const initialState: InfoBillingState = {
  isLoading: false,
  hasErrorMemoDownload: false,
  isLoadingDownloadMemo: false,
  invoiceDownload: "",
  params: {},
  page: 0,
  size: 20,
  count: 0,
  city: "",
  billingsIssued: invoiceState,
  billingsPaid: invoiceState,
  billingsExpires: invoiceState,
  billingsShow: [],
  billingsDownload: [],
  isLoadingDetail: false,
  idMemoInvoice: "",
  selectedFilterInvoice: "Facturas emitidas",
  errorDownloadAll: false,
  successDownloadAll: false,
  isLoadingDownload: false,
  isLoadingDownloadAll: false,
  isLoadingStatistics: false,
  errorDownload: false,
  successDownload: false,
  detailInvoice: null,
  totalPayment: 0,
  countBillingsIssued: 0,
  countBillingsPaid: 0,
  countBillingsExpires: 0,
  amounts: {},
};

export const downloadInvoice = createAsyncThunk("billing/downloadInvoice", async (invoiceId: string) => {
  let response = await api.get(`/billing/invoices/${invoiceId}/document/`, {
    params: {},
    responseType: "arraybuffer",
  });
  const file = new Blob([response.data], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = `${invoiceId}.pdf`;
  link.click();
  URL.revokeObjectURL(fileURL);
});

export const downloadInvoiceTable = createAsyncThunk(
  "billing/downloadInvoiceTable",
  async (typeDownload: string, thunkAPI) => {
    let invoices: Invoice[] = [];
    const state = thunkAPI.getState() as StateStorage;
    const getVarToDownload = conversionVarsInitialState[typeDownload as keyof typeof conversionVarsInitialState];
    let query = state.infoBillingState[getVarToDownload as keyof typeof state.infoBillingState] as RootObject;

    if (query.total <= 1000) {
      const params = {
        page: 0,
        size: 1000,
        list_statistics: typeDownload,
        ...state.infoBillingState.params,
      };
      const res = await api.get("/billing/invoices/", { params: params });
      invoices = res.data.items;
    } else {
      let size = 500;
      let totalQuery = query?.total;
      let differencePerPage = totalQuery / size;
      let totalPages = Math.ceil(differencePerPage);

      for (let index = 0; index < totalPages; index++) {
        const params = {
          page: index,
          size: size,
          list_statistics: typeDownload,
          ...state.infoBillingState.params,
        };
        const res = await api.get("/billing/invoices/", { params: params });
        invoices = [...invoices, ...res.data.items];
      }
    }
    return invoices;
  }
);

export const getBillings = createAsyncThunk("billing/getBillings", async (_, thunkAPI) => {
  await Promise.all([
    thunkAPI.dispatch(infoBillingsIssued()),
    thunkAPI.dispatch(infoBillingsPaid()),
    thunkAPI.dispatch(infoBillingsExpires()),
  ]);
});

export const infoBillingsIssued = createAsyncThunk("billing/infoBillingsIssued", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as StateStorage;
  const params = getParamsBilling(state, "Facturas emitidas");
  const res = await api.get("/billing/invoices/", params);
  return res.data;
});

export const infoBillingsPaid = createAsyncThunk("billing/infoBillingsPaid", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as StateStorage;
  const params = getParamsBilling(state, "Facturas pagadas");
  const res = await api.get("/billing/invoices/", params);
  return res.data;
});

export const downloadMemo = createAsyncThunk("billing/downloadMemo", async (memoId: string) => {
  const res = await api.get(`/billing/memos/${memoId}/document`, {
    responseType: "arraybuffer",
  });
  const file = new Blob([res.data], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = `${memoId}.pdf`;
  link.click();
  URL.revokeObjectURL(fileURL);
  return res.data;
});

export const infoBillingsExpires = createAsyncThunk("billing/infoBillingsExpires", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as StateStorage;
  const params = getParamsBilling(state, "Facturas vencidas");
  const res = await api.get("/billing/invoices/", params);
  return res.data;
});

export const getMemosInvoices = createAsyncThunk("invoices/getMemosInvoices", async (id: string) => {
  {
    const res = await api.get("/billing/invoice-memos/", {
      params: {
        page: 0,
        size: 50,
        invoice_id: id,
      },
    });

    return res.data.items;
  }
});

export const infoBillingSlice = createSlice({
  name: "infoBillingState",
  initialState,
  reducers: {
    cleanHasErrorMemo: (state) => {
      state.hasErrorMemoDownload = false;
    },
    setResetDownloadInvoice: (state) => {
      state.billingsDownload = [];
    },
    setInvoices: (state, action: PayloadAction<string>) => {
      state.selectedFilterInvoice = action.payload;
      if (action.payload === "Facturas emitidas") {
        state.billingsShow = state.billingsIssued.items;
        state.count = state.countBillingsIssued;
      } else if (action.payload === "Facturas pagadas") {
        state.billingsShow = state.billingsPaid.items;
        state.count = state.countBillingsPaid;
      } else if (action.payload === "Facturas vencidas") {
        state.billingsShow = state.billingsExpires.items;
        state.count = state.countBillingsExpires;
      }
    },
    setResetPage: (state) => {
      state.page = 0;
    },
    setResetSearch: (state) => {
      state.city = "";
    },
    setParams: (state, action: PayloadAction<ParamFilters>) => {
      state.params = action.payload;
    },
    setValuesDetail: (state, action: PayloadAction<Invoice>) => {
      state.detailInvoice = action.payload;
    },
    setTotalPayment: (state, action: PayloadAction<number>) => {
      state.totalPayment = action.payload;
    },
    setInvoiceMemos: (state, action: PayloadAction<string>) => {
      state.idMemoInvoice = action.payload;
    },
    setResetVars: (state) => {
      state.errorDownload = false;
      state.successDownload = false;
      state.errorDownloadAll = false;
      state.successDownloadAll = false;
    },
    setSelectedFilterInvoice: (state, action: PayloadAction<string>) => {
      state.selectedFilterInvoice = action.payload;
    },
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setInvoiceDownload: (state, action: PayloadAction<string>) => {
      state.invoiceDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadInvoiceTable.pending.type, (state) => {
        state.isLoadingDownloadAll = true;
        initDownload(true);
      })
      .addCase(downloadInvoiceTable.rejected.type, (state) => {
        state.isLoadingDownloadAll = false;
        state.errorDownloadAll = true;
        state.successDownloadAll = false;
        state.billingsDownload = [];
        initDownload(false);
      })
      .addCase(downloadInvoiceTable.fulfilled.type, (state, action: PayloadAction<Invoice[]>) => {
        state.isLoadingDownloadAll = false;
        state.errorDownloadAll = false;
        state.successDownloadAll = true;
        state.billingsDownload = action.payload;
        initDownload(false);
      })
      .addCase(getBillings.pending.type, (state) => {
        state.isLoadingStatistics = true;
      })
      .addCase(getBillings.rejected.type, (state) => {
        state.isLoadingStatistics = false;
      })
      .addCase(getBillings.fulfilled.type, (state) => {
        state.isLoadingStatistics = false;
      })
      .addCase(infoBillingsIssued.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(infoBillingsIssued.rejected.type, (state) => {
        state.isLoading = false;
        state.billingsIssued = invoiceState;
        state.amounts.issued = {
          issuedInvoiceAmount: 0,
        };
        state.countBillingsIssued = 0;
      })
      .addCase(infoBillingsIssued.fulfilled.type, (state, action: PayloadAction<RootObject>) => {
        state.isLoading = false;
        state.billingsIssued = action.payload;
        state.amounts.issued = {
          issuedInvoiceAmount: action.payload.total,
        };
        state.countBillingsIssued = action.payload.pages;
      })
      .addCase(infoBillingsPaid.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(infoBillingsPaid.rejected.type, (state) => {
        state.isLoading = false;
        state.billingsPaid = invoiceState;
        state.amounts.paid = {
          paidInvoiceAmount: 0,
        };
        state.countBillingsPaid = 0;
      })
      .addCase(infoBillingsPaid.fulfilled.type, (state, action: PayloadAction<RootObject>) => {
        state.isLoading = false;
        state.billingsPaid = action.payload;
        state.amounts.paid = {
          paidInvoiceAmount: action.payload.total,
        };
        state.countBillingsPaid = action.payload.pages;
      })
      .addCase(infoBillingsExpires.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(infoBillingsExpires.rejected.type, (state) => {
        state.isLoading = false;
        state.billingsExpires = invoiceState;
        state.amounts.expires = {
          expiresInvoiceAmount: 0,
        };
        state.countBillingsExpires = 0;
      })
      .addCase(infoBillingsExpires.fulfilled.type, (state, action: PayloadAction<RootObject>) => {
        state.isLoading = false;
        state.billingsExpires = action.payload;
        state.amounts.expires = {
          expiresInvoiceAmount: action.payload.total,
        };
        state.countBillingsExpires = action.payload.pages;
      })
      .addCase(downloadInvoice.pending.type, (state) => {
        state.isLoadingDownload = true;
      })
      .addCase(downloadInvoice.rejected.type, (state) => {
        state.isLoadingDownload = false;
        state.invoiceDownload = "";
        state.errorDownload = true;
        state.successDownload = false;
      })
      .addCase(downloadInvoice.fulfilled.type, (state) => {
        state.isLoadingDownload = false;
        state.invoiceDownload = "";
        state.successDownload = true;
        state.errorDownload = false;
      })
      .addCase(downloadMemo.pending.type, (state) => {
        state.isLoadingDownloadMemo = true;
        state.hasErrorMemoDownload = false;
      })
      .addCase(downloadMemo.rejected.type, (state) => {
        state.isLoadingDownloadMemo = false;
        state.hasErrorMemoDownload = true;
      })
      .addCase(downloadMemo.fulfilled.type, (state) => {
        state.isLoadingDownloadMemo = false;
      })
      .addCase(getMemosInvoices.pending.type, (state) => {
        state.isLoadingDetail = true;
      })
      .addCase(getMemosInvoices.rejected.type, (state) => {
        state.isLoadingDetail = false;
        state.idMemoInvoice = "";
      })
      .addCase(getMemosInvoices.fulfilled.type, (state, action: PayloadAction<Memos[]>) => {
        state.isLoadingDetail = false;
        state.idMemoInvoice = "";
        if (state.detailInvoice) {
          state.detailInvoice.legal_payable_amount = getValuePayment(
            state.detailInvoice.legal_payable_amount,
            action.payload
          );
          state.detailInvoice = {
            ...state.detailInvoice,
            memos: action.payload,
          };
        }
      });
  },
});

export const {
  setParams,
  setSelectedFilterInvoice,
  setInvoiceMemos,
  handlePageChange,
  setInvoiceDownload,
  setResetVars,
  setValuesDetail,
  setTotalPayment,
  setInvoices,
  setResetPage,
  setResetSearch,
  setResetDownloadInvoice,
  cleanHasErrorMemo,
} = infoBillingSlice.actions;

export const isLoading = (state: StateStorage) => state.infoBillingState.isLoading;
export const params = (state: StateStorage) => state.infoBillingState.params;
export const invoiceDownload = (state: StateStorage) => state.infoBillingState.invoiceDownload;
export const isLoadingDownload = (state: StateStorage) => state.infoBillingState.isLoadingDownload;
export const successDownload = (state: StateStorage) => state.infoBillingState.successDownload;
export const errorDownload = (state: StateStorage) => state.infoBillingState.errorDownload;
export const detailInvoice = (state: StateStorage) => state.infoBillingState.detailInvoice;
export const totalPayment = (state: StateStorage) => state.infoBillingState.totalPayment;
export const selectedFilterInvoice = (state: StateStorage) => state.infoBillingState.selectedFilterInvoice;
export const page = (state: StateStorage) => state.infoBillingState.page;
export const size = (state: StateStorage) => state.infoBillingState.size;
export const count = (state: StateStorage) => state.infoBillingState.count;
export const amounts = (state: StateStorage) => state.infoBillingState.amounts;
export const isLoadingStatistics = (state: StateStorage) => state.infoBillingState.isLoadingStatistics;
export const billingsShow = (state: StateStorage) => state.infoBillingState.billingsShow;
export const errorDownloadAll = (state: StateStorage) => state.infoBillingState.errorDownloadAll;
export const successDownloadAll = (state: StateStorage) => state.infoBillingState.successDownloadAll;
export const isLoadingDownloadAll = (state: StateStorage) => state.infoBillingState.isLoadingDownloadAll;
export const isLoadingDetail = (state: StateStorage) => state.infoBillingState.isLoadingDetail;
export const idMemoInvoice = (state: StateStorage) => state.infoBillingState.idMemoInvoice;
export const billingsDownload = (state: StateStorage) => state.infoBillingState.billingsDownload;

export default infoBillingSlice.reducer;
