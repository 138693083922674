import { BoltIcon, Box, CustomAlert, formatterPeso, moment } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { cleanHasErrorMemo } from "../../features/infoBilling/infoBilling";
import { StateStorage } from "../../models/stateStorage";
import { AppDispatch } from "../../store/store";
import MemosInfo from "./MemosInfo";
import InvoiceInfo from "./billingInfo";

const DetailForm = () => {
  const { detailInvoice } = useSelector((state: StateStorage) => state.infoBillingState);

  const { hasErrorMemoDownload } = useSelector((state: StateStorage) => state.infoBillingState);
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Box>
      <Box className="Box-detail-invoice">
        <Box sx={{ marginBottom: "18px" }}>
          <Box sx={{ fontSize: "18px" }}>Detalle de tu factura en:</Box>
          <Box sx={{ fontSize: "16px", fontWeight: "700" }}>{detailInvoice?.address}</Box>
          <Box sx={{ fontSize: "18px" }}>Número de id: {detailInvoice?.id}</Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="center">
            <Box className="Icon-bolt-invoice">
              <BoltIcon />
            </Box>
          </Box>
          <Box sx={{ fontSize: "12px", marginTop: "10px" }}>Total a pagar</Box>
          <Box sx={{ fontSize: "34px", fontWeight: "700" }}>
            {formatterPeso.format(detailInvoice?.legal_payable_amount || 0)}
          </Box>
          <Box sx={{ fontSize: "14px" }}>
            Fecha de vencimiento: {moment(detailInvoice?.curtailment_date).format("YYYY-MM-DD")}
          </Box>
        </Box>
      </Box>

      {hasErrorMemoDownload && (
        <CustomAlert
          onClose={() => dispatch(cleanHasErrorMemo())}
          sx={{ width: "100%", margin: "1.3rem 0 1.3rem 0" }}
          severity={"error"}
          text={"Ocurrió un error, inténtelo nuevamente"}
        />
      )}
      {/* Facturas */}
      <InvoiceInfo />
      {/* Cobros */}
      <MemosInfo />
    </Box>
  );
};

export default DetailForm;
