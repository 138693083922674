import {
  Box,
  Grid, 
  MonetizationOnOutlinedIcon,
  CircularProgress,
} from "@enerbit/base";
import CustomCard from "@enerbit/base/common/components/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import {
  amounts, 
  isLoadingStatistics,
  selectedFilterInvoice,
  setInvoices, 
  setResetPage,
} from "../../features/infoBilling/infoBilling";
import { AppDispatch } from "../../store/store";

const CardOverview = () => {

  const selectedCard = useSelector(selectedFilterInvoice);
  const totalAmounts = useSelector(amounts);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingStatistics); 
  
  const InvoicesCard = (value: string, amount: number) => {
    return <CustomCard
      onClick={() => {
        dispatch(setInvoices(value));
        dispatch(setResetPage());
      }} 
      icon={<MonetizationOnOutlinedIcon color="primary"/> }
      text={value}
      value={value}
      totalAmounts={amount}
      selectedCard={selectedCard}
    /> 
  }

  return (
    <>
      {loading ? (
        <Box className="Loading-flex">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Grid sx={{ flexGrow: 1, mt: "12px" }} container>
            <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
              { InvoicesCard("Facturas emitidas", totalAmounts?.issued?.issuedInvoiceAmount || 0) }  
            </Grid>
            <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
              { InvoicesCard("Facturas pagadas", totalAmounts?.paid?.paidInvoiceAmount || 0) }   
            </Grid>
            <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
              { InvoicesCard("Facturas vencidas", totalAmounts?.expires?.expiresInvoiceAmount || 0) }    
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CardOverview;
