import * as singleSpa from "single-spa";

const mountedAppSingleSpaEssBillingDetail = (
  serviceTypeName: string,
  invoiceId: string
) => {
  singleSpa.registerApplication(
    "app-ess-billing-detail",
    (): Promise<singleSpa.LifeCycles> =>
      System.import("@enerBit/ess-billing-detail"),
    (location) => location.pathname.startsWith("/"),
    { serviceTypeName, invoiceId }
  );
};

export const mountedDisassembleAppsEssBillingDetail = async (
  serviceTypeName: string,
  invoiceId: string
) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-ess-billing-detail");

  if (appNamesAccess) {
    singleSpa.unregisterApplication("app-ess-billing-detail").then(async () => {
      await mountedAppSingleSpaEssBillingDetail(serviceTypeName, invoiceId);
    });
  } else {
    await mountedAppSingleSpaEssBillingDetail(serviceTypeName, invoiceId);
  }
};
