import { store } from "../store/store";
import { Provider } from "react-redux";
import {
  ThemeConfig,
  PrivateRoute
} from "@enerbit/base";
import SectionInformation from "../pages/SectionInformation";

export default function Routers() {
  return <Provider store={store}>
    <ThemeConfig>
      <PrivateRoute>
        <SectionInformation />
      </PrivateRoute>
    </ThemeConfig>
  </Provider>
} 