export const conversionVarsInitialState = {
    "Facturas emitidas": "billingsIssued",
    "Facturas pagadas": "billingsPaid",
    "Facturas vencidas": "billingsExpires"
}

export const headersInvoices = [ 
    { label: "id", key: "id" },
    { label: "curtailment_date", key: "curtailment_date" },
    { label: "legal_payable_amount", key: "legal_payable_amount" },
    { label: "issued_at", key: "issued_at" },
    { label: "due_at", key: "due_at" },
    { label: "payment_date", key: "payment_date" },
    { label: "tax_inclusive_amount", key: "tax_inclusive_amount" },
    { label: "tax_exclusive_amount", key: "tax_exclusive_amount" },
    { label: "total_tax_amount", key: "total_tax_amount" },
    { label: "legal_total_tax_amount", key: "legal_total_tax_amount" },
    { label: "currency_code", key: "currency_code" },
    { label: "service_settlement_id", key: "service_settlement_id" },
    { label: "service_id", key: "service_id" },
    { label: "stamp_code", key: "stamp_code" },
    { label: "is_electronic_invoice", key: "is_electronic_invoice" },
    { label: "invoice_numeration", key: "invoice_numeration" },
    { label: "service_type_name", key: "service_type_name" },
    { label: "service_type_id", key: "service_type_id" },
    { label: "service_account_id", key: "service_account_id" },
    { label: "legal_id_type", key: "legal_id_type" },
    { label: "legal_id_code", key: "legal_id_code" },
    { label: "email", key: "email" },
    { label: "address", key: "address" },
    { label: "state", key: "state" },
    { label: "city", key: "city" },
    { label: "country", key: "country" },
    { label: "first_name", key: "first_name" },
    { label: "last_names", key: "last_names" },
    { label: "details", key: "details" },
    { label: "created_at", key: "created_at" },
    { label: "updated_at", key: "updated_at" },
    { label: "name", key: "name" },
    { label: "components", key: "components" },
]; 