import { useDispatch, useSelector } from "react-redux";
import {
  moment
} from "@enerbit/base"; 
import {
  infoBillingsExpires,
  infoBillingsIssued,
  infoBillingsPaid,
  selectedFilterInvoice,
  setInvoices,
} from "../features/infoBilling/infoBilling";
import { Memos, ParamsAsync } from "../models/infoBillingState";
import { StateStorage } from "../models/stateStorage";
import { AppDispatch } from "../store/store"; 

export const callFunctionInvoices = async () => {
  const selectedCard = useSelector(selectedFilterInvoice);
  const dispatch = useDispatch<AppDispatch>();

  if (selectedCard == "Facturas emitidas") {
    await dispatch(infoBillingsIssued());
    dispatch(setInvoices("Facturas emitidas"));
  } else if (selectedCard == "Facturas pagadas") {
    await dispatch(infoBillingsPaid());
    dispatch(setInvoices("Facturas pagadas"));
  } else {
    await dispatch(infoBillingsExpires());
    dispatch(setInvoices("Facturas vencidas"));
  }
};

export const getParamsBilling = (state: StateStorage, billingType: string) => {
  let params: ParamsAsync = {
    params: {
      page: state.infoBillingState.page,
      size: state.infoBillingState.size,
      /* sizeTableDefaultDownload: state.infoBillingState.sizeTableDefaultDownload */
      ...state.infoBillingState.params,
      list_statistics: billingType,
    },
  };
  return params;
};

export const getDatePayExpired = (expirationDate: Date) => {
  var dateExpires = moment(expirationDate);
  var dateSubstract = moment();

  let days = dateSubstract.diff(dateExpires, 'days');

  if(days < 0){
    return "0";
  }else{
    return days;
  } 
};
