import {
  Box,
  DownloadIcon,
  ErrorOutlineOutlinedIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
  LoadingButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  formatterPeso,
  translateDetailBillingName,
} from "@enerbit/base";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailInvoice,
  downloadMemo,
} from "../../features/infoBilling/infoBilling";
import { AppDispatch } from "../../store/store";

const MemosInfo = () => {
  const [seeCorrection, setCorrection] = useState(true);
  const detail = useSelector(detailInvoice);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Fragment>
      {detail?.memos &&
        detail.memos.map((memo, index) => {
          return (
            <Box key={index} className="Box-detail-invoice-repeat">
              <Box className="Box-detail-invoice-border">
                <Box
                  onClick={() => {
                    setCorrection(!seeCorrection);
                  }}
                  className="Grid-container-main Box-flex-invoices"
                >
                  <Box
                    className="Icon-detail-invoice"
                    sx={{
                      background:
                        memo.legal_payable_amount < 0 &&
                        Math.sign(memo.legal_payable_amount) == -1
                          ? "var(--color-success) !important"
                          : "var(--color-error500) !important",
                    }}
                  >
                    <ErrorOutlineOutlinedIcon />
                  </Box>
                  <Box sx={{ fontWeight: "700", width: "100%" }}>
                    <Box sx={{ color: "var(--color-neutral500)" }}>
                      Correción factura
                    </Box>
                    <Box
                      sx={{
                        color:
                          memo.legal_payable_amount < 0 &&
                          Math.sign(memo.legal_payable_amount) == -1
                            ? "var(--color-success) !important"
                            : "var(--color-error500)",
                      }}
                    >
                      {memo.legal_payable_amount > 0 ? "+" : ""}{" "}
                      {formatterPeso.format(memo.legal_payable_amount)}
                    </Box>
                  </Box>
                  <LoadingButton
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(downloadMemo(memo.id));
                    }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="Loading-button Button-xs"
                  >
                    <DownloadIcon />
                  </LoadingButton>
                  <Box className="Icon-arrow-accordeon">
                    {seeCorrection ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </Box>
                </Box>
                {seeCorrection && (
                  <>
                    <Box className="Paper-table">Ajuste de la factura</Box>
                    <TableContainer
                      component={Paper}
                      className="Paper-table-container"
                    >
                      <Table
                        className="Table-detail-invoices"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>Concepto</TableCell>
                            <TableCell align="right">Ajuste</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {memo.invoice_memo_components.map(
                            (components, index) => (
                              <TableRow
                                key={components.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="left"
                                  sx={{
                                    color: "var(--color-neutral700) !important",
                                  }}
                                >
                                  {translateDetailBillingName(
                                    components.name
                                  ) || components.name}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color:
                                      memo.legal_payable_amount < 0 &&
                                      Math.sign(memo.legal_payable_amount) == -1
                                        ? "var(--color-success) !important"
                                        : "var(--color-error500) !important",
                                    fontWeight: "700",
                                  }}
                                >
                                  {components.amount > 0 ? "+" : ""}
                                  {formatterPeso.format(components.amount)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
    </Fragment>
  );
};

export default MemosInfo;
