import {
  Box,
  CSVLink,
  CustomAlert,
  CustomModalTypes,
  DownloadOutlinedIcon,
  LoadingButton,
  ModalLimitDownload,
  Pagination,
  Paper,
  StatusChip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  formatterPeso,
  getStatusDate,
  getTypeStatusInvoices,
  moment,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import { ChangeEvent, createRef, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headersInvoices } from "../../enums/billing";
import {
  amounts,
  billingsDownload,
  billingsShow,
  count,
  downloadInvoice,
  downloadInvoiceTable,
  errorDownload,
  errorDownloadAll,
  getMemosInvoices,
  handlePageChange,
  idMemoInvoice,
  infoBillingsExpires,
  infoBillingsIssued,
  infoBillingsPaid,
  invoiceDownload,
  isLoadingDetail,
  isLoadingDownload,
  isLoadingDownloadAll,
  page,
  selectedFilterInvoice,
  setInvoiceDownload,
  setInvoiceMemos,
  setInvoices,
  setResetDownloadInvoice,
  setResetVars,
  setValuesDetail,
  successDownload,
  successDownloadAll,
} from "../../features/infoBilling/infoBilling";
import { getDatePayExpired } from "../../helpers/helpersBilling";
import { PropsTableInvoice } from "../../models/infoBillingState";
import { AppDispatch } from "../../store/store";
import { getDialogComponentByName } from "../../utils/getGeneralModalEssBillingDetail";

const TableBilling = ({ openDialogInformation }: PropsTableInvoice) => {
  const hasErrorDownload = useSelector(errorDownload);
  const hasSuccessDownload = useSelector(successDownload);
  const hasErrorDownloadAll = useSelector(errorDownloadAll);
  const hasSuccessDownloadAll = useSelector(successDownloadAll);
  const loadingDownload = useSelector(isLoadingDownload);
  const loadingDownloadAll = useSelector(isLoadingDownloadAll);
  const invoice = useSelector(invoiceDownload);
  const countInvoices = useSelector(count);
  const pageInvoices = useSelector(page);
  const items = useSelector(billingsShow);
  const dispatch = useDispatch<AppDispatch>();
  const selectedCard = useSelector(selectedFilterInvoice);
  const loadingDetail = useSelector(isLoadingDetail);
  const memoInvoice = useSelector(idMemoInvoice);
  const invoicesDownload = useSelector(billingsDownload);
  const csvLinkEl = createRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >();
  const limitDownload = useRef<CustomModalTypes>(null);
  const totalAmounts = useSelector(amounts);

  const callPagination = async (event: ChangeEvent<unknown>, value: number) => {
    dispatch(handlePageChange(value - 1));
    if (selectedCard == "Facturas emitidas") {
      await dispatch(infoBillingsIssued());
      dispatch(setInvoices("Facturas emitidas"));
    } else if (selectedCard == "Facturas pagadas") {
      await dispatch(infoBillingsPaid());
      dispatch(setInvoices("Facturas pagadas"));
    } else {
      await dispatch(infoBillingsExpires());
      dispatch(setInvoices("Facturas vencidas"));
    }
  };

  const csvReport = {
    data: invoicesDownload.map((value) => {
      return {
        ...value,
        components: JSON.stringify(value.components).replaceAll(",", ";"),
        details: JSON.stringify(value.details).replaceAll(",", ";"),
        invoice_numeration: JSON.stringify(value.invoice_numeration).replaceAll(
          ",",
          ";"
        ),
      };
    }),
    headers: headersInvoices,
    filename: moment().format("YMMDD") + "_facturas.csv",
  };

  const callLimitDownload = () => {
    if (limitDownload.current) {
      limitDownload.current.changeModal();
    }
  };

  const getDownload = () => {
    let totalValue = 0;

    if (selectedCard == "Facturas emitidas") {
      totalValue = totalAmounts?.issued?.issuedInvoiceAmount || 0;
    } else if (selectedCard == "Facturas pagadas") {
      totalValue = totalAmounts?.paid?.paidInvoiceAmount || 0;
    } else if (selectedCard == "Facturas vencidas") {
      totalValue = totalAmounts?.expires?.expiresInvoiceAmount || 0;
    }

    if (totalValue > 500) {
      callLimitDownload();
    } else {
      dispatch(downloadInvoiceTable(selectedCard));
    }
  };

  useEffect(() => {
    if (invoicesDownload.length > 0) {
      if (csvLinkEl.current) {
        csvLinkEl.current.link.click();
        dispatch(setResetDownloadInvoice());
      }
    }
  }, [invoicesDownload]);

  return (
    <>
      {(hasErrorDownload || hasSuccessDownload) && (
        <CustomAlert
          onClose={() => dispatch(setResetVars())}
          sx={{ width: "100%", margin: "1.3rem 0 1.3rem 0" }}
          severity={hasErrorDownload ? "error" : "success"}
          text={
            hasErrorDownload
              ? "Ocurrió un error, inténtelo nuevamente"
              : "Factura descargada correctamente"
          }
        />
      )}
      {(hasErrorDownloadAll || hasSuccessDownloadAll) && (
        <CustomAlert
          onClose={() => dispatch(setResetVars())}
          sx={{ width: "100%", margin: "1.3rem 0 1.3rem 0" }}
          severity={hasErrorDownloadAll ? "error" : "success"}
          text={
            hasErrorDownloadAll
              ? "Ocurrió un error, inténtelo nuevamente"
              : "Factura descargada correctamente"
          }
        />
      )}
      <Box className="Table-container-invoice">
        <CSVLink ref={csvLinkEl} className="Csv-download" {...csvReport} />
        <LoadingButton
          onClick={getDownload}
          variant="outlined"
          color="primary"
          size="small"
          sx={{ margin: "1.8rem 0 0.5rem 0" }}
          startIcon={<DownloadOutlinedIcon />}
          loading={loadingDownloadAll}
        >
          <Box style={{ fontWeight: "bold", margin: 0 }}>Descargar</Box>
        </LoadingButton>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table className="Table-billing" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Tipo de servicio</TableCell>
              <TableCell align="left">Ciudad</TableCell>
              <TableCell align="left">Dirección</TableCell>
              <TableCell align="left">Fecha emisión</TableCell>
              <TableCell align="left">Fecha límite</TableCell>
              {selectedCard === "Facturas emitidas" && (
                <TableCell align="left">Estado</TableCell>
              )}
              {selectedCard === "Facturas pagadas" && (
                <TableCell align="left">Fecha pago</TableCell>
              )}
              {selectedCard === "Facturas vencidas" && (
                <TableCell align="left">Días de mora</TableCell>
              )}
              <TableCell>Valor</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((row, index) => {
                let status = getStatusDate(row.payment_date, row.due_at);
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {" "}
                      {row.id.substring(0, 8)}{" "}
                    </TableCell>
                    <TableCell align="left"> {row.first_name} </TableCell>
                    <TableCell align="left">
                      {" "}
                      {serviceNames[row.service_type_name]
                        ? serviceNames[row.service_type_name]
                        : thirdPartyServiceNames[row.service_type_name]}{" "}
                    </TableCell>
                    <TableCell align="left">{row.city}</TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">
                      {moment(row.issued_at).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.due_at).format("YYYY-MM-DD")}
                    </TableCell>
                    {selectedCard === "Facturas pagadas" && (
                      <TableCell align="left" className="Text-color-payments">
                        {row.payment_date
                          ? moment(row.payment_date).format("YYYY-MM-DD")
                          : ""}
                      </TableCell>
                    )}
                    {selectedCard === "Facturas vencidas" && (
                      <TableCell
                        align="left"
                        className="Text-color-payments-expired"
                      >
                        {row.due_at ? `+${getDatePayExpired(row.due_at)}` : ""}
                      </TableCell>
                    )}
                    {selectedCard === "Facturas emitidas" && (
                      <TableCell align="left">
                        <StatusChip
                          label={<Box>{status}</Box>}
                          type={getTypeStatusInvoices(status)}
                        />
                      </TableCell>
                    )}
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      {formatterPeso.format(row.legal_payable_amount)}
                    </TableCell>
                    <TableCell align="left">
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        loading={
                          loadingDownload && invoice == row.id ? true : false
                        }
                        sx={{ minWidth: "59px", borderRadius: "14px" }}
                        className="Loading-button"
                        onClick={() => {
                          dispatch(setInvoiceDownload(row.id));
                          dispatch(downloadInvoice(row.id));
                        }}
                      >
                        <DownloadOutlinedIcon sx={{ fontSize: "21px" }} />
                      </LoadingButton>
                    </TableCell>
                    <TableCell align="left">
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="Loading-button Button-xs"
                        loading={memoInvoice == row.id && loadingDetail}
                        onClick={async () => {
                          dispatch(setInvoiceMemos(row.id));
                          dispatch(setValuesDetail(row));
                          await dispatch(getMemosInvoices(row.id));

                          getDialogComponentByName(
                            row.service_type_name,
                            row.id,
                            openDialogInformation
                          );
                        }}
                      >
                        <Box style={{ fontWeight: "bold", margin: 0 }}>
                          Ver detalle
                        </Box>
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="Pagination-invoices">
        <Pagination
          count={countInvoices}
          shape="rounded"
          page={pageInvoices + 1}
          sx={{ margin: "0.3rem 0 1rem 0" }}
          onChange={callPagination}
        />
      </Box>
      <ModalLimitDownload ref={limitDownload} />
    </>
  );
};

export default TableBilling;
